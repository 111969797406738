export default [
  {
    title: 'Contacts',
    icon: 'address-book',
    resource: 'Contacts',
    children: [
      {
        title: 'Map',
        route: 'contactsMap',
        resource: 'contactsMap',
        action: 'read',
      },
      {
        title: 'Companies',
        route: 'contactCompanies',
        resource: 'ContactCompanies',
        action: 'read',
      },
      {
        title: 'Individuals',
        route: 'contactUsers',
        resource: 'ContactUsers',
        action: 'read',
      },
    ]
  }
]
