<template>
  <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
  >
    <template #button-content>
      <icon icon="bell"
            badge="6"
            badgeColor="danger"
            size="lg"/>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
            pill
            variant="light-primary"
        >
          6 New
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
    >
      <li v-for="notification in notifications"
          :key="notification.subtitle">
        <b-link>
          <b-media>
            <template #aside>
              <b-avatar
                  size="32"
                  :src="notification.avatar"
                  :text="notification.avatar"
                  :variant="notification.type"
              />
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.title }}
              </span>
            </p>
            <small class="notification-text">{{ notification.subtitle }}</small>
          </b-media>
        </b-link>
      </li>
    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer">
      <b-button
          variant="primary"
          block
      >Read all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  components: {
    VuePerfectScrollbar: () => import('vue-perfect-scrollbar')
  },
  data () {
    return {
      notifications: [
        {
          title: 'Congratulation Sam 🎉',
          avatar: 'SM',
          subtitle: 'Won the monthly best seller badge',
          type: 'light-success',
        },
        {
          title: 'New message received',
          avatar: 'JH',
          subtitle: 'You have 10 unread messages',
          type: 'light-info',
        },
        {
          title: 'Revised Order 👋',
          avatar: 'MD',
          subtitle: 'MD Inc. order updated',
          type: 'light-danger',
        },
      ],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }
    }
  },

}
</script>

<style>

</style>
