import router from '@/router'
import { isObject } from '@/utils/utils'
import { computed } from '@vue/composition-api'

export const resolveNavMenuItemComponent = item => {
  if (item.header) return 'nav-menu-header'
  if (item.children) return 'nav-menu-group'
  return 'nav-menu-link'
}

// export const resolveHorizontalNavMenuItemComponent = item => {
//   if (item.children) return 'horizontal-nav-menu-group'
//   return 'horizontal-nav-menu-link'
// }

export const resolveNavDataRouteName = link => {
  if (isObject(link.route)) {
    const { route } = router.resolve(link.route)
    return route.name
  }
  return link.route
}

export const isNavLinkActive = link => {
  const matchedRoutes = router.currentRoute.matched
  const resolveRoutedName = resolveNavDataRouteName(link)

  if (!resolveRoutedName) return false

  return matchedRoutes.some(route => route.name === resolveRoutedName || route.meta.navActiveLink === resolveRoutedName)
}

export const isNavGroupActive = children => {
  const matchedRoutes = router.currentRoute.matched

  return children.some(child => {
    if (child.children) {
      return isNavGroupActive(child.children)
    }

    return isNavLinkActive(child, matchedRoutes)
  })
}

export const navLinkProps = item => computed(() => {
  const props = {}

  if (item.route) props.to = typeof item.route === 'string' ? { name: item.route } : item.route
  else {
    props.href = item.href
    props.target = '_blank'
    props.rel = 'nofollow'
  }

  if (!props.target) props.target = item.target || null

  return props
})
