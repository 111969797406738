<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveNavMenuItemComponent as resolveNavItemComponent } from '@/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import NavMenuHeader from '../header/index'
import NavMenuLink from '../link/Link'
import NavMenuGroup from '../group/Group'

export default {
  components: {
    NavMenuHeader,
    NavMenuLink,
    NavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
