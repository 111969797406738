<template>
  <b-nav-item-dropdown
      class="dropdown-cart mr-25"
      menu-class="dropdown-menu-media"
      right
      @show="fetchItems"
  >
    <template #button-content>
      <icon icon="shopping-cart"
            size="lg"
            :badge="items.length"/>
      <!--      <fa-icon icon="shopping-cart" size="lg" />-->
      <!--      <span class="badge badge-up badge-pill badge-primary">0</span>-->

    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          My Cart
        </h4>
        <b-badge
            pill
            variant="light-primary"
        >
          <!--          {{ $store.state['app-ecommerce'].cartItemsCount }} Items-->
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
        v-if="items.length"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >
      <b-media
          v-for="item in items"
          :key="item.name"
      >
        <template #aside>
          <b-img
              :src="item.image"
              :alt="item.name"
              rounded
              width="62px"
          />
        </template>
        <feather-icon
            icon="XIcon"
            class="cart-item-remove cursor-pointer"
            @click.stop="removeItemFromCart(item.id)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.name }}
            </b-link>
          </h6>
          <small class="cart-item-by">By {{ item.brand }}</small>
        </div>

        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
              v-model="item.qty"
              min="1"
              size="sm"
          />
        </div>

        <h5 class="cart-item-price">
          ${{ item.price }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
        v-if="items.length"
        class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          ${{ totalAmount }}
        </h6>
      </div>
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          :to="{ name: 'apps-e-commerce-checkout' }"
      >
        Checkout
      </b-button>
    </li>

    <p
        v-if="!items.length"
        class="m-0 p-1 text-center"
    >
      Your cart is empty
    </p>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    totalAmount () {
      let total = 0
      this.items.forEach(i => { total += i.price })
      return total
    },
  },
  methods: {
    fetchItems () {
      // this.$store.dispatch('app-ecommerce/fetchCartProducts')
      //   .then(response => {
      //     this.items = response.data.products
      //   })
    },
    removeItemFromCart () {
      // this.$store.dispatch('app-ecommerce/removeProductFromCart', { productId })
      //   .then(() => {
      //     const itemIndex = this.items.findIndex(p => p.id === productId)
      //     this.items.splice(itemIndex, 1)
      //
      //     // Update count in cart items state
      //     this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
      //   })
    },
  },
}
</script>

<style>

</style>
