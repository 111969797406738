export default [
  // {
  //   title: 'Warehouse',
  //   icon: 'book-open',
  //   children: [
  //     {
  //       title: 'Dashboard',
  //       route: 'Warehouse dashboard',
  //       resource: 'WarehouseDashboard',
  //       action: 'manage',
  //     },
  //   ]
  // },
]
