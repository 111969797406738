<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
    >
      <slot name="breadcrumb">
<!--        <app-breadcrumb />-->
      </slot>
      <div class="content-body">
        <transition
          name="zoom-fade"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
// import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'

export default {
  components: {
    // AppBreadcrumb,
  },
}
</script>

<style>

</style>
