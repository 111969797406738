export default [
  {
    title: 'HumanResources',
    icon: 'user-tie',
    resource: 'HumanResources',
    children: [
      {
        title: 'Dashboard',
        route: 'Human Resources dashboard',
        resource: 'HRDashboard',
        action: 'manage',
      },
      {
        title: 'Employees',
        route: 'Employees2',
        resource: 'Employees',
        action: 'read',
      },
      // {
      //   title: 'Salaries',
      //   route: 'Salaries'
      // },
      {
        title: 'Salaries',
        route: 'Salaries2'
      },
      {
        title: 'Holidays',
        route: 'Holidays3',
        resource: 'Holidays',
        action: 'manage',
      },
      // {
      //   title: 'SocialSecurityContributions',
      //   route: 'SocialSecurityContributions',
      //   resource: 'SocialSecurityContributions',
      //   action: 'manage',
      // },
      {
        title: 'Charges',
        route: 'Charges',
        resource: 'Charges',
        action: 'manage',
      },
      {
        title: 'Movements',
        route: 'Movements',
        resource: 'Movements',
        action: 'manage',
      }
    ]
  },
]
