import { ref, computed } from '@vue/composition-api'
import store from '@/store'

export default function useVerticalLayout () {
  const isVerticalMenuActive = ref(true)
  const toggleVerticalMenuActive = () => {
    isVerticalMenuActive.value = !isVerticalMenuActive.value
  }

  const currentBreakpoint = ref('xl')

  const isVerticalMenuCollapsed = computed(() => store.state.app.isVerticalMenuCollapsed)

  const layoutClasses = computed(() => {
    const classes = []

    if (currentBreakpoint.value === 'xl') {
      classes.push('vertical-menu-modern')
      classes.push(isVerticalMenuCollapsed.value ? 'menu-collapsed' : 'menu-expanded')
    } else {
      classes.push('vertical-overlay-menu')
      classes.push(isVerticalMenuActive.value ? 'menu-open' : 'menu-hide')
    }

    return classes
  })

  const resizeHandler = () => {
    isVerticalMenuActive.value = window.innerWidth >= 1200

    currentBreakpoint.value = store.getters['app/currentBreakPoint']
  }

  const overlayClasses = computed(() => {
    if (currentBreakpoint.value !== 'xl' && isVerticalMenuActive.value) return 'show'
    return null
  })

  // const navbarTypeClass = computed(() => {
  //   if (navbarType.value === 'sticky') return 'fixed-top'
  //   if (navbarType.value === 'static') return 'navbar-static-top'
  //   if (navbarType.value === 'hidden') return 'd-none'
  //   return 'floating-nav'
  // })
  //
  // const footerTypeClass = computed(() => {
  //   if (footerType.value === 'static') return 'footer-static'
  //   if (footerType.value === 'hidden') return 'd-none'
  //   return ''
  // })

  return {
    isVerticalMenuActive,
    toggleVerticalMenuActive,
    // isVerticalMenuCollapsed,
    layoutClasses,
    overlayClasses,
    // navbarTypeClass,
    // footerTypeClass,
    resizeHandler,
  }
}
