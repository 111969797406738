export default [
  {
    title: 'Sales',
    icon: 'euro-sign',
    resource: 'Sales',
    action: 'read',
    children: [
      {
        title: 'Dashboard',
        route: 'Sales dashboard',
        resource: 'SalesDashboard',
        action: 'manage',
      },
      {
        title: 'Offers',
        route: 'Offers2',
        resource: 'Offers',
        action: 'read',
      },
      // {
      //   title: 'Offers old',
      //   route: 'Offers'
      // },
      // {
      //   title: 'OutgoingInvoices',
      //   route: 'OutgoingInvoices'
      // },
      {
        title: 'Customer invoices',
        route: 'Customer invoices3',
        resource: 'CustomerInvoices',
        action: 'read',
      },
    ]
  },
]
