<template>
  <li
      v-if="!item.resource || item.children.some(i => $can(item.action, item.resource))"
      class="nav-item has-sub"
      :class="{
      'open': isOpen,
      'disabled': item.disabled,
      'sidebar-group-active': isActive,
    }"
  >
    <b-link
        class="d-flex align-items-center"
        @click="() => updateGroupOpen(!isOpen)"
    >
      <icon :icon="item.icon || 'circle'"/>
      <span class="menu-title text-truncate ml-1">{{ $t(item.title) }}</span>
      <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse
        v-model="isOpen"
        class="menu-content"
        tag="ul"
    >
      <component
          :is="resolveNavItemComponent(child)"
          v-for="child in item.children"
          :key="child.header || child.title"
          ref="groupChild"
          :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { resolveNavMenuItemComponent as resolveNavItemComponent } from '@/layouts/utils'
import NavMenuHeader from '../header/index'
import NavMenuLink from '../link/Link'

// import { ref } from '@vue/composition-api'

// Composition Function
import useNavMenuGroup from './useGroup'
import mixinNavMenuGroup from './mixinGroup'

export default {
  components: {
    NavMenuHeader,
    NavMenuLink,
  },
  mixins: [mixinNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useNavMenuGroup(props.item)

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    }
  },
}
</script>

<style>

</style>
