<template>
  <div
      class="main-menu menu-fixed menu-accordion menu-shadow menu-light"
      :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }
    ]"
      @mouseenter="updateMouseHovered(true)"
      @mouseleave="updateMouseHovered(false)"
  >

    <div class="navbar-header expanded">
      <slot
          name="header"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :toggleCollapsed="toggleCollapsed"
          :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <li class="nav-item mr-auto">
            <b-link
                class="navbar-brand"
                to="/"
            >
              <span class="brand-logo">
                <b-img
                    :src="require('@/assets/images/logo/logo.png')"
                    alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle"
                    @click="toggleVerticalMenuActive">
              <icon icon="times"
                    size="lg"
                    class="d-block d-xl-none"
              />
              <!--              <feather-icon-->
              <!--                  :icon="collapseTogglerIconFeather"-->
              <!--                  size="20"-->
              <!--                  class="d-none d-xl-block collapse-toggle-icon"-->
              <!--                  @click="toggleCollapsed"-->
              <!--              />-->
            </b-link>
          </li>
        </ul>
      </slot>
    </div>

    <div
        :class="{'d-block': shallShadowBottom}"
        class="shadow-bottom"
    />

    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <menu-items
          :items="navMenuItems"
          class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/index'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { provide, ref } from '@vue/composition-api'
import MenuItems from './components/items/Items'
import useSidebar from './useSidebar'
import useAppConfig from '@/utils/useAppConfig'

export default {
  components: {
    VuePerfectScrollbar,
    MenuItems,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup (props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useSidebar(props)
    //
    const { appName } = useAppConfig()
    //
    // // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    //
    // const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    //
    // // App Name
    // const { appName, appLogoImage } = $themeConfig.app
    //
    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      //   collapseTogglerIconFeather,
      //
      //   // Shadow Bottom
      shallShadowBottom,
      //
      //   // Skin
      //   skin,
      //
      //   // App Name
      appName,
      //   appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
