export default [
  {
    title: 'CRM',
    icon: 'link',
    children: [
      {
        title: 'Dashboard',
        route: 'WorkFlos dashboard',
        resource: 'CRMIndicators',
        action: 'manage',
      },
      {
        title: 'My dashboard',
        route: 'My CRM dashboard',
        resource: 'WorkFlos',
      },
      {
        title: 'Map',
        route: 'WorkFlosMap',
        resource: 'WorkFlosMap',
        action: 'read',
      },
      {
        title: 'Opportunités',
        route: 'WorkFlos'
      },
    ]
  },
]
