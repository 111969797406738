<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.firstName }} {{ userData.name }}
        </p>
        <!--        <span class="user-status">{{ userData.role }}</span>-->
      </div>
      <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
      >
      </b-avatar>
    </template>


    <b-dropdown-item
        :to="{ name: 'AccountSettings' }"
        link-class="d-flex align-items-center"
    >

      <icon icon="cog"
            class="mr-50"/>
      <span>{{ $t('Settings') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider/>

    <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
    >
      <icon icon="sign-out-alt"
            class="mr-50"/>
      <span>{{ $t('Logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
// import { initials } from '@/utils/utils'

export default {
  components: {},
  // setup () {
  //   return {
  //     initials
  //   }
  // },
  data () {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      // userData: {},

    }
  },
  computed: {
    fullName () {
      return this.userData.firstName + ' ' + this.userData.name
    }
  },
  methods: {
    logout () {
      console.log('ok')
      this.$store.dispatch('auth/logout')
          .then(() => this.$router.push({ name: 'auth-login' }))
    },
  },
}
</script>
