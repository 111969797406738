export default [
  {
    title: 'Indicators',
    // icon: 'chart-line',
    icon: 'tachometer-alt',
    route: 'Indicators',
    resource: 'Indicators',
    action: 'manage',


    // title: 'Dashboards',
    // icon: 'HomeIcon',
    // tag: '2',
    // tagVariant: 'light-warning',
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //   },
    // ],
  },
]
