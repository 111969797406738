// import accounting from './accounting'
import accounting2    from './accounting2'
import contacts       from './contacts'
import contacts2      from './contacts2'
import dashboard      from './dashboard'
import indicators     from './indicators'
import others         from './others'
import purchases      from './purchases'
import sales          from './sales'
import support        from './support'
import warehouse      from './warehouse'
import workFlos       from './workFlos'
import workflows      from './workflows'
import humanResources from './humanResources'
import emails         from './emails'
import parameters     from './parameters'

export default [
  ...dashboard,
  ...emails,
  // ...contacts,
  ...contacts2,
  ...workFlos,
  ...indicators,
  ...warehouse,
  ...others,
  ...purchases,
  ...sales,
  ...workflows,
  // ...accounting,
  ...accounting2,
  ...humanResources,
  ...support,
  ...parameters,
]
