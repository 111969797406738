<template>
  <b-nav-item-dropdown
      id="dropdown-grouped"
      variant="link"
      class="dropdown-language"
      right
  >
    <template #button-content>
      <b-img
          :src="currentLocale.img"
          height="14px"
          width="22px"
          :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
    </template>
    <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click="$i18n.locale = localeObj.locale"
    >
      <b-img
          :src="localeObj.img"
          height="14px"
          width="22px"
          :alt="localeObj.locale"
      />
      <span class="ml-50">{{ $t(localeObj.name) }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      locales: [
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: 'English',
        },
        {
          locale: 'fr',
          img: require('@/assets/images/flags/fr.png'),
          name: 'French',
        },
      ]
    }
  },
  computed: {
    currentLocale () {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
}
</script>

<style>

</style>
